@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

#app {
  height: 100vh;
  background: url(./images/bg.jpg) no-repeat center center;
  background-size: cover;
}

.MuiDrawer-paper {
  background-image: linear-gradient(
    94.3deg,
    rgb(128, 129, 133) 110.9%,
    rgb(252, 252, 252) 87.1%
  ) !important;
}

.MuiTableContainer-root {
  background: transparent !important;
  box-shadow: none !important;
}
.MuiPaper-rounded {
  background-image: linear-gradient(
    94.3deg,
    rgb(128, 129, 133) 110.9%,
    rgb(252, 252, 252) 87.1%
  );
}
